export const createSite = async (sessionToken, siteName) => {
    const response = await fetch(
      `https://3nhn06phfk.execute-api.us-east-1.amazonaws.com/v1/site/createSite?siteId=7eb2f0e3-c45a-4f0e-97a7-d7b4edbb6c37&siteName=${siteName}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: sessionToken,
        },
      }
    );
    return await response.json();
  };