import { Routes, Route } from "react-router-dom";
import "./App.css";
import Sidenav from "./Components/Sidenav";
import Home from "./Pages/Home";
import Settings from "./Pages/Settings";
import Assets from "./Pages/Assets/Assets";
import Metrics from "./Pages/Metrics/Metrics";
import User from "./Pages/User";
import { useAuth0 } from "@auth0/auth0-react";
import Callback from "./Components/Callback";

function App() {
  const { isLoading, isAuthenticated, error, loginWithRedirect } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }
  if (!isAuthenticated) {
    loginWithRedirect();
    return <div>Loading...</div>;
  }
  return (
    <div className="App">
      <Sidenav />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/assets" element={<Assets />} />
          <Route path="/Metrics" element={<Metrics />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/userinfo" element={<User />} />
          <Route path="/callback" element={<Callback />} />
        </Routes>
      </main>
    </div>
  );
}

export default App;