import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { createSite } from "./createSite";
import { listSites } from "./listSites";

const Assets = () => {
  const [edgeId] = useState("108");
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [siteName, setSiteName] = useState('');
  const { user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getUserData = async () => {
      try {
        setLoading(true);
        setError(null);
        const accessToken = await getAccessTokenSilently({});
        const response = await listSites(accessToken);
        setData(response);
        console.log(data)
      } catch (e) {
        setError("An error occurred while fetching the data.");
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    };
  
    getUserData();
  }, [getAccessTokenSilently, user?.sub, edgeId, setError, setLoading]);
  
  const handleCreateSite = async () => {
    try {
      setLoading(true);
      setError(null);
      const accessToken = await getAccessTokenSilently({});
      await createSite(accessToken, siteName);
      setSiteName('');
      const response = await listSites(accessToken);
      setData(response);
    } catch (e) {
      setError("An error occurred while creating the site.");
      console.log(e.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="App">
      <body>
        <h1>My Sites</h1>
        <div>
          <label>Site Name: </label>
          <input type="text" value={siteName} onChange={e => setSiteName(e.target.value)} />
          <button onClick={handleCreateSite}>Create Site</button>
        </div>
        {isLoading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error}</div>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Site Name</th>
                <th>Site Id</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.id}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </body>
    </div>
  );
};

export default Assets;
