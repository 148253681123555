import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

function Callback() {
  const { isLoading, isAuthenticated, error } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      window.location.href = "/";
    }
  }, [isAuthenticated]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }
  return null;
}

export default Callback;
