import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { currentValues } from "./currentValues";

const Home = () => {
  const assetId = "d60c4771-a320-44c6-a450-40b5b91f8397";
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getCurrentValues = async () => {
      try {
        setLoading(true);
        setError(null);
        const accessToken = await getAccessTokenSilently({});
        const response = await currentValues(accessToken, assetId);
        setData(response);
        console.log(response);
      } catch (e) {
        setError("An error occurred while fetching the data.");
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    };

    getCurrentValues();
  }, [getAccessTokenSilently, user?.sub, assetId]);

  return (
    <div className="App">
      <body>
        <h1>Today`s Quote</h1>

        <h1></h1>
        <p>
          In the heart of NOV, stands the K-building tall, where innovation
          takes flight, and creativity calls. And in the K-building, where all
          dreams come true, NOV and its people create anew.
        </p>
      </body>
    </div>
  );
};

export default Home;
