// In myApiHelpers.js

export const listSites = async (sessionToken) => {
  const response = await fetch(
    `https://3nhn06phfk.execute-api.us-east-1.amazonaws.com/v1/site/listSites?siteId=7eb2f0e3-c45a-4f0e-97a7-d7b4edbb6c37`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionToken,
      },
    }
  );
  return await response.json();
};
