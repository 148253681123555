import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { currentValues } from "./currentValues";

const Home = () => {
  const assetId = "d60c4771-a320-44c6-a450-40b5b91f8397";
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getCurrentValues = async () => {
      try {
        setLoading(true);
        setError(null);
        const accessToken = await getAccessTokenSilently({});
        const response = await currentValues(accessToken, assetId);
        setData(response);
        console.log(response);
      } catch (e) {
        setError("An error occurred while fetching the data.");
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    };

    getCurrentValues();
  }, [getAccessTokenSilently, user?.sub, assetId]);

  return (
    <div className="App">
      <body>
        <h1>My Favorite CNC</h1>
        {isLoading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error}</div>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Asset Id</th>
                <th>Type</th>
                <th>Value</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.type}</td>
                  <td>{item.property_value}</td>
                  <td>
                    {item.timestamp &&
                      new Date(
                        item.timestamp.timeInSeconds * 1000
                      ).toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </body>
    </div>
  );
};

export default Home;
